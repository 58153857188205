import React from "react";
import styles from "./Menu.scss";
// import gus from "../../assets/images/icon-gus-button.png";
// import discover from "../../assets/images/icon-discover-button.png";

// import efficacy from "../../assets/images/icon-efficacy-button.png";

// import safety from "../../assets/images/icon-safety-button.png";

// import voyage from "../../assets/images/icon-voyage-button.png";

// import axial from "../../assets/images/icon-axial-button.png";

export default (props) => (
  <>
		<div className="menu">
			<a className="menu_item         Guselkumab" href="#Guselkumab">
        {/* <img src={gus} alt="" /> */}
				<div className="menu_item--text">
          <span>GUSELKUMAB</span>
        </div>
      </a>
			<a className="menu_item        Discover" href="#Discover">
        {/* <img src={discover} alt="" /> */}
				<div className="menu_item--text">
          <span> DISCOVER TRIALS (PsA)</span>
        </div>
      </a>
			<a className="menu_item      Efficacy" href="#Efficacy">
        {/* <img src={efficacy} alt="" /> */}
				<div className="menu_item--text">
          <span> EFFICACY (PsA) </span>
        </div>
      </a>
    </div>
		<div className="menu">
			<a className="menu_item Axial" href="#Axial">
        {/* <img src={axial} alt="" /> */}
				<div className="menu_item--text">
          <span>EFFICACY: AXIAL DISEASE (PsA)</span>
        </div>
      </a>
			<a className="menu_item   Safety" href="#Safety">
        {/* <img src={safety} alt="" /> */}
				<div className="menu_item--text safety">
          <span>SAFETY (PsA)</span>
        </div>
      </a>
			<a className="menu_item     Voyage" href="#Voyage">
        {/* <img src={voyage} alt="" /> */}
				<div className="menu_item--text">
          <span> VOYAGE TRIALS (PsO)</span>
        </div>
      </a>
    </div>
    {/* <div class="footnote"> */}
    {/* <sup>*</sup>Peripheral Arthritis, Enthesitis, and Dactylitis
      <br />
      <sup>†</sup>Skin and Nails
      <br /> <br /> */}
    {/* </div> */}
    <hr />
  </>
);
