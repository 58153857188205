//create isi function

function isi() {
  window.iFrameResizer = {
    onMessage: (message) => {
      if (message === "RESIZE_EVENT_EXECUTED") {
				parentIFrame.getPageInfo((pageInfo) => {
          let isiSize = pageInfo.clientHeight * 0.25 + "px";
          let isiFromTop = pageInfo.clientHeight * 0.75;
          let iframeFromTop = pageInfo.offsetTop;
          let isi = document.getElementById("indications-isi");
          let isi2 = document.getElementById("safety-isi");

          isi.classList.remove("open");
          isi2.classList.remove("open");
          document.getElementById("safety-text").style.overflowY = "hidden";

          resetISI = () => {
            isi.classList.remove("open");
						isi2.classList.remove("open");
            isi.style.width = "100%";
            isi.style.height = isiSize;
            isi.style.top =
              pageInfo.scrollTop + isiFromTop * 1.2 - iframeFromTop + "px";
            isi.style.position = "absolute";
            isi.style.zIndex = "2";
          };

          resetISI2 = () => {
            isi.classList.remove("open");
						isi2.classList.remove("open");
            isi2.style.width = "100%";
            isi2.style.height = isiSize;
            isi2.style.top =
              pageInfo.scrollTop + isiFromTop * 0.95 - iframeFromTop + "px";

            if (window.innerWidth < 720) {
              isi2.style.top =
                pageInfo.scrollTop +
                isiFromTop * 1.1 -
                iframeFromTop -
                100 +
                "px";
            }

            if (
              window.innerWidth < 720 &&
              window.innerHeight > window.innerWidth
            ) {
              isi2.style.top =
                pageInfo.scrollTop +
                isiFromTop * 1.1 -
                iframeFromTop -
                50 +
                "px";
            }
            isi2.style.left = pageInfo.scrollX + "px";
            isi2.style.position = "absolute";
            isi2.style.zIndex = "1";
          };

          // document.body.style.paddingBottom =
          //   pageInfo.clientHeight * 0.5 + "px";

          isi.onclick = function () {
            if (isi.classList.contains("open")) {
              resetISI();
              resetISI2();
              isi.classList.remove("open");
              return;
            }
            resetISI();
            resetISI2();
            //ifisi.has class of open

            isi.style.height = parseFloat(isiSize) * 3 + "px";
            isi.style.top =
              pageInfo.scrollTop +
              isiFromTop -
              (iframeFromTop + parseFloat(isi.style.height) / 7.5) +
              "px";
            isi.classList.add("open");

            isi2.style.top =
              pageInfo.scrollTop +
              isiFromTop -
              (iframeFromTop + parseFloat(isi.style.height) / 3.5) +
              "px";
          };
          //isi reset on clicks aswell as scroll
          isi2.onclick = function () {
            if (isi2.classList.contains("open")) {
              resetISI();
              resetISI2();
              isi2.classList.remove("open");
              document.getElementById("safety-text").style.height = "100%";
							document.getElementById("safety-text").style.overflowY = "hidden";
              return;
            }
            resetISI();
            resetISI2();

            isi2.style.height =
              parseFloat(isiSize) + pageInfo.clientHeight * 0.5 + "px";
            isi2.style.top =
              pageInfo.scrollTop +
              isiFromTop -
              (iframeFromTop + parseFloat(isi.style.height)) -
              pageInfo.clientHeight * 0.35 +
              "px";
            document.getElementById("safety-text").style.height =
              parseFloat(isiSize) + pageInfo.clientHeight * 0.5 + "px";
            document.getElementById("safety-text").style.overflowY = "scroll";
            isi2.classList.add("open");
          };

          if (window.innerWidth < 720) {
						let wideImageBlocks = document.querySelectorAll("img");
            for (let i = 0; i < wideImageBlocks.length; i++) {
              wideImageBlocks[i].style.maxHeight =
                pageInfo.clientHeight * 0.5 + "px";
            }
          }
          resetISI();
          resetISI2();
        });
      }
    },
  };
}

isi();

//module.exports
module.exports = { isi };
