import React from "react";
import styles from "./TitleBlockBackground.scss";

export default (props) => (
  <>
    <hr />
    <div className="TitleBlockBackground">
      {/* <hr /> */}
      <h2>{props.block.headline}</h2>
      <p dangerouslySetInnerHTML={{ __html: props.block.text }}></p>
    </div>
  </>
);
