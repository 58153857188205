import React from "react";
import "./ISI.scss";

export default (props) => {
  return (
    <>
      <div id="indications-isi">
				<h4 className="isi-header-2">
					<div className="isi-header-content">
						<span className="isi-header-2">
							INDICATIONS <span className="plus">+</span>
							<span className="minus">-</span>
            </span>{" "}
						<span className="fa-minus__span">
							<i className="fas fa-minus"></i>
            </span>
          </div>
        </h4>
				<div className="isi-content__body-b">
          {/* <b>Guselkumab Indications</b> */}
          {/* <br /> */}

          <p>
            Guselkumab is indicated for the treatment of adult patients with
            moderate-to-severe plaque psoriasis who are candidates for systemic
            therapy or phototherapy.
            <br /> <br /> Guselkumab is indicated for the treatment of adult
            patients with active psoriatic arthritis.
          </p>
          {/* <br /> */}
					<p className="footnote">
            TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham,
            PA: Janssen Biotech, Inc.
          </p>
        </div>
      </div>
      <div id="safety-isi">
				<h4 className="isi-header-1">
					<div className="isi-header-content">
						<span className="isi-header-1">
							IMPORTANT SAFETY INFORMATION <span className="plus">+</span>
							<span className="minus">-</span>
            </span>{" "}
						<span className="fa-minus__span">
							<i className="fas fa-minus"></i>
            </span>
          </div>
        </h4>
				<div className="isi-content__body">
					<div className="isi-content__body-a" id="safety-text">
            <p>
              <b>CONTRAINDICATIONS</b>
              <br />
              Guselkumab is contraindicated in patients with a history of
              serious hypersensitivity reaction to guselkumab or to any of the
              excipients.
              <br /> <br />
              <b> WARNINGS AND PRECAUTIONS</b>
              <br /> <br />
              <b>Hypersensitivity Reactions</b> <br />
              Serious hypersensitivity reactions, including anaphylaxis, have
              been reported with postmarket use of guselkumab. Some cases
              required hospitalization. If a serious hypersensitivity reaction
              occurs, discontinue guselkumab and initiate appropriate therapy.
              <br /> <br />
              <b>Infections</b>
              <br />
              Guselkumab may increase the risk of infection. Treatment with
              guselkumab should not be initiated in patients with a clinically
              important active infection until the infection resolves or is
              adequately treated. <br /> <br />
              Consider the risks and benefits of treatment prior to prescribing
              guselkumab in patients with a chronic infection or a history of
              recurrent infection. Instruct patients receiving guselkumab to
              seek medical help if signs or symptoms of clinically important
              chronic or acute infection occur. If a patient develops a
              clinically important or serious infection, or is not responding to
              standard therapy, closely monitor and discontinue guselkumab until
              the infection resolves.
              <br /> <br />
              <b>Pre-Treatment Evaluation for Tuberculosis (TB)</b> <br />
              Evaluate patients for TB infection prior to initiating treatment
              with guselkumab. Initiate treatment of latent TB prior to
              administering guselkumab. Monitor patients for signs and symptoms
              of active TB during and after guselkumab treatment. Do not
              administer guselkumab to patients with active TB infection.
              <br />
              <br />
              <b>Immunizations</b> <br />
              Prior to initiating guselkumab, consider completion of all
              age-appropriate immunizations according to current immunization
              guidelines. Avoid use of live vaccines in patients treated with
              guselkumab.
              <br />
              <br />
              <b>ADVERSE REACTIONS</b> <br />
              Most common (≥1%) adverse reactions associated with guselkumab
              include upper respiratory infections, headache, injection site
              reactions, arthralgia, bronchitis, diarrhea, gastroenteritis,
              tinea infections, and herpes simplex infections. <br /> <br />
              The overall safety profile observed in patients with psoriatic
              arthritis is generally consistent with the safety profile in
              patients with plaque psoriasis, with the addition of bronchitis
              and neutrophil count decreased. <br />
              <br />
              <b>
                Please read the full Prescribing Information and Medication
                Guide for guselkumab at TremfyaHCP.com. Provide the Medication
                Guide to your patients and encourage discussion.
              </b>
              <br /> <br />
							<p className="footnote"> cp-82625v3</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
      <div id="safety-isi-static" style={{ position: "relative" }}>
				<h4 className="isi-header-1">
					<div className="isi-header-content">
						<span className="isi-header-1">
							IMPORTANT SAFETY INFORMATION <span className="plus"></span>
							<span className="minus">-</span>
            </span>{" "}
						<span className="fa-minus__span">
							<i className="fas fa-minus"></i>
            </span>
          </div>
        </h4>
				<div className="isi-content__body">
					<div className="isi-content__body-a" id="safety-text">
            <p>
              <b>CONTRAINDICATIONS</b>
              <br />
              Guselkumab is contraindicated in patients with a history of
              serious hypersensitivity reaction to guselkumab or to any of the
              excipients.
              <br /> <br />
              <b> WARNINGS AND PRECAUTIONS</b>
              <br /> <br />
              <b>Hypersensitivity Reactions</b> <br />
              Serious hypersensitivity reactions, including anaphylaxis, have
              been reported with postmarket use of guselkumab. Some cases
              required hospitalization. If a serious hypersensitivity reaction
              occurs, discontinue guselkumab and initiate appropriate therapy.
              <br /> <br />
              <b>Infections</b>
              <br />
              Guselkumab may increase the risk of infection. Treatment with
              guselkumab should not be initiated in patients with a clinically
              important active infection until the infection resolves or is
              adequately treated. <br /> <br />
              Consider the risks and benefits of treatment prior to prescribing
              guselkumab in patients with a chronic infection or a history of
              recurrent infection. Instruct patients receiving guselkumab to
              seek medical help if signs or symptoms of clinically important
              chronic or acute infection occur. If a patient develops a
              clinically important or serious infection, or is not responding to
              standard therapy, closely monitor and discontinue guselkumab until
              the infection resolves.
              <br /> <br />
              <b>Pre-Treatment Evaluation for Tuberculosis (TB)</b> <br />
              Evaluate patients for TB infection prior to initiating treatment
              with guselkumab. Initiate treatment of latent TB prior to
              administering guselkumab. Monitor patients for signs and symptoms
              of active TB during and after guselkumab treatment. Do not
              administer guselkumab to patients with active TB infection.
              <br />
              <br />
              <b>Immunizations</b> <br />
              Prior to initiating guselkumab, consider completion of all
              age-appropriate immunizations according to current immunization
              guidelines. Avoid use of live vaccines in patients treated with
              guselkumab.
              <br />
              <br />
              <b>ADVERSE REACTIONS</b> <br />
              Most common (≥1%) adverse reactions associated with guselkumab
              include upper respiratory infections, headache, injection site
              reactions, arthralgia, bronchitis, diarrhea, gastroenteritis,
              tinea infections, and herpes simplex infections. <br /> <br />
              The overall safety profile observed in patients with psoriatic
              arthritis is generally consistent with the safety profile in
              patients with plaque psoriasis, with the addition of bronchitis
              and neutrophil count decreased. <br />
              <br />
              <b>
                Please read the full Prescribing Information and Medication
                Guide for guselkumab at TremfyaHCP.com. Provide the Medication
                Guide to your patients and encourage discussion.
              </b>
              <br /> <br />
							<p className="footnote"> cp-82625v3</p>
              <br />
            </p>
          </div>
        </div>
      </div>
      <div
        id="indications-isi-static"
        style={{ position: "relative", height: "auto", width: "auto" }}
      >
				<h4 className="isi-header-2">
					<div className="isi-header-content">
						<span className="isi-header-2">
							INDICATIONS <span className="plus"></span>
							<span className="minus">-</span>
            </span>{" "}
						<span className="fa-minus__span">
							<i className="fas fa-minus"></i>
            </span>
          </div>
        </h4>
				<div className="isi-content__body-b">
          {/* <b>Guselkumab Indications</b> */}
          {/* <br /> */}

          <p>
            Guselkumab is indicated for the treatment of adult patients with
            moderate-to-severe plaque psoriasis who are candidates for systemic
            therapy or phototherapy.
            <br /> <br /> Guselkumab is indicated for the treatment of adult
            patients with active psoriatic arthritis.
          </p>
          {/* <br /> */}
					<p className="footnote">
            TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham,
            PA: Janssen Biotech, Inc.
          </p>
        </div>
      </div>
    </>
  );
};
