import React from "react";
import Menu from "./components/Menu/Menu";
import TitleBlockBackground from "./components/TitleBlockBackground/TitleBlockBackground";
import EyebrowHeadlineText from "./components/EyebrowHeadlineText/EyebrowHeadlineText";
import TitleWithIcon from "./components/TitleWithIcon/TitleWithIcon";
import Tab from "./components/Tab/Tab";
import TabPasi from "./components/TabPasi/TabPasi";
import TabVoyage from "./components/TabVoyage/TabVoyage";
import TabEnthesitisDactylitis from "./components/TabEnthesitisDactylitis/TabEnthesitisDactylitis";
const Components = {
  TitleBlockBackground: TitleBlockBackground,
  Menu: Menu,
  EyebrowHeadlineText: EyebrowHeadlineText,
  TitleWithIcon: TitleWithIcon,
  Tab: Tab,
  TabPasi: TabPasi,
  TabEnthesitisDactylitis: TabEnthesitisDactylitis,
  TabVoyage: TabVoyage,
};

export default (block) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
