import React, { useState, useEffect } from "react";
import "./TabVoyage.scss";
import image1 from "../../assets/images/51-endpoints.png";
import image2 from "../../assets/images/53-treatment.png";
import image3 from "../../assets/images/54-treatment.png";

export default (props) => {
  const [Tab, setTab] = useState(0);

  const style0 = {
    backgroundImage:
      Tab === 0
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style1 = {
    backgroundImage:
      Tab === 1
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style2 = {
    backgroundImage:
      Tab === 2
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };

  useEffect(() => {
    document.getElementById(`tabv-${Tab}`).classList.add("fade-in");
    document.getElementById(`tabv-${Tab}`).classList.remove("fade-out");
		for (let i = 0; i <= 2; i++) {
			if (i != Tab) {
        document.getElementById("tabv-" + i).classList.remove("fade-in");
        document.getElementById("tabv-" + i).classList.add("fade-out");
      }
    }
  }, [Tab]);

  return (
    <>
      <div className="Tab TabVoyage" id="TabVoyage">
        <div className={"tabLinksWrap"}>
          <button
            style={style0}
            className={"tabLinks"}
            onClick={() => setTab(0)}
          >
            <span className={"tabLinksText"}>PASI 90 and IGA 0/1</span>
          </button>
          <button
            style={style1}
            className={"tabLinks"}
            onClick={() => setTab(1)}
          >
            <span className={"tabLinksText"}>Scalp</span>
          </button>
          <button
            style={style2}
            className={"tabLinks"}
            onClick={() => setTab(2)}
          >
            <span className={"tabLinksText"}>Nails, Palms, & Soles</span>
          </button>
        </div>

        <div className={"tabcontent"} id="tabv-0">
          <div className="EyebrowHeadlineText">
            <h4>
              Co-primary Endpoints at Week 16 in Moderate to Severe Plaque
              Psoriasis: PASI 90 and IGA 0/1
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={image1} alt="" />
            </div>
						<p className="imgnote">
              *<i>P</i>&#60;0.001 vs placebo, based on the
              Cochran-Mantel-Haenszel chi-square test stratified by investigator
              site (pooled).<sup>2</sup>
              <br />
              NRI methods were used for analysis.
              <br /> <br />
              IGA 0/1=Proportion of patients who achieved IGA score of cleared
              (0) or minimal (1) using a 5-point scale where psoriatic lesions
              are graded by the investigator for induration, erythema, and
              scaling on a scale of 0 to 4: cleared, except for residual
              discoloration (0), minimal (1), mild (2), moderate (3), or severe
              (4); PASI 90=Proportion of patients who achieved 90% or more
              reduction (or improvement) in PASI score from baseline.
              <br />
              <br />
              IGA=Investigator’s Global Assessment; NRI=nonresponder imputation;
              PASI=Psoriasis Area and Severity Index.
              <br /> <br /> 1. TREMFYA
              <sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA:
              Janssen Biotech, Inc. 2. Data on file. Janssen Biotech, Inc.
            </p>
          </div>
        </div>
        <div className={"tabcontent"} id="tabv-1">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}>
              {" "}
              In Adults With Moderate to Severe Plaque Psoriasis
            </h5>
            <h4>
              Treatment Considerations in Difficult-to-Treat Patient Types
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={image2} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>In a subpopulation of patients with ss-IGA score ≥2
                at baseline.
              </b>
              <br />
              <b>
                <sup>†</sup>Prespecified other secondary analysis that was not
                adjusted for multiplicity; therefore, statistical significance
                has not been established.
              </b>
              <br />
              Active comparator at Week 48 not shown. <br />
              <br />
              ss-IGA 0/1=proportion of patients who achieved an ss-IGA score of
              absence of disease (0) or very mild (1) using a 5-point scale
              where scalp lesions were assessed in terms of clinical signs of
              redness, thickness, and scaliness on a scale of 0 to 4: Absence of
              disease (0), very mild (1), mild (2), moderate (3), or severe (4).
              <br /> <br />
              NRI=nonresponder imputation; ss-IGA=scalp-specific Investigator
              Global Assessment. <br />
              <br />
              1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information].
              Horsham, PA: Janssen Biotech, Inc. 2. Blauvelt A, et al.{" "}
              <i> J Am Acad Dermatol</i>. 2017;76(3):405-417. 3. Reich K,
              Armstrong AW. <i>J Am Acad Dermatol</i>. 2017;76(3):418-431.
            </div>
          </div>
        </div>
        <div className={"tabcontent"} id="tabv-2">
          <div className="EyebrowHeadlineText">
            <h5 className="">
              In Adults With Moderate to Severe Plaque Psoriasis
            </h5>
            <h4>
              Treatment Considerations in Difficult-to-Treat Patient Types
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={image3} alt="" />
            </div>
						<div className="footnote">
              <sup>†</sup>Fingernail psoriasis was assessed using NAPSI, in
              which the nail most affected by psoriasis (target nail) is divided
              into quadrants and graded for psoriasis of the nail matrix
              (pitting, leukonychia, red spots in the lunula, and nail
              crumbling) and nail bed (onycholysis, splinter hemorrhages, oil
              drop discoloration, and nail bed hyperkeratosis) on a scale of 0
              to 4 for a total score ranging from 0 to 8; a higher score
              indicates more severe disease.
              <br />
              <b>
                <sup>‡</sup>Limitation: All 10 fingernails may also be evaluated
                using NAPSI.
              </b>
              <br />
              <b>
                <sup>§</sup>Prespecified other secondary analysis that was not
                adjusted for multiplicity; therefore, statistical significance
                has not been established.
              </b>
              <br />
              Active comparator at Week 48 not shown.
              <br /> <br />
              hf-PGA 0/1=Patients who achieved hf-PGA score of clear (0) or
              almost clear (1) using a 5-point scale based on the category that
              best describes the severity of psoriasis on the palms and soles on
              a scale of 0 to 4: Clear, postinflammatory hyperpigmentation may
              be present (0), almost clear (1), mild (2), moderate (3), or
              severe (4). <br />
              <br />
              NAPSI=Nail Psoriasis Severity Index; NRI=nonresponder imputation;
              hf-PGA=Physician Global Assessment of hands and/or feet.
              <br /> <br />
              1. Blauvelt A, et al. <i>J AM Acad Dermatol</i>.
              2017;76(3):405-417.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
