import React, { useState, useEffect } from "react";
import "./Tab.scss";
import response15 from "../../assets/images/15-response.png";
import response16 from "../../assets/images/16-response.png";
import response17 from "../../assets/images/17-response.png";
import response18 from "../../assets/images/18-response.png";
import response19 from "../../assets/images/19-response.png";
import discover2 from "../../assets/images/discover/discover-2.png";
import discover1 from "../../assets/images/discover/discover-1.png";

//
//create style
//create two buttons, one for each tab
export default (props) => {
  //add settab state
  const [Tab, setTab] = useState(0);

  const style0 = {
    backgroundImage:
      Tab === 0
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style1 = {
    backgroundImage:
      Tab === 1
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };

  //make it so when tab changes element fades in and out
  useEffect(() => {
    document.getElementById(`tab-${Tab}`).classList.add("fade-in");
    document.getElementById(`tab-${Tab}`).classList.remove("fade-out");
    //loop through all document.getElementById("tab-" + i)")
    for (let i = 0; i <= 1; i++) {
      if (i != Tab) {
        document.getElementById("tab-" + i).classList.remove("fade-in");
        document.getElementById("tab-" + i).classList.add("fade-out");
      }
    }
  }, [Tab]);

  return (
    <>
      <div className="Tab" id="Tab">
        <div className={"tabLinksWrap"}>
          <button
            style={style0}
            className={"tabLinks"}
            onClick={() => setTab(0)}
          >
            <span className={"tabLinksText"}>ACR 20</span>
          </button>
          <button
            style={style1}
            className={"tabLinks"}
            onClick={() => setTab(1)}
          >
            <span className={"tabLinksText"}>ACR 50 and 70</span>
          </button>
        </div>

        {/* {Tab === 0 ? ( */}
        <div className={"tabcontent"} id="tab-0">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              ACR20 Response Through Week 100 (NRI)<sup>†‡§1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={response15} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>ACR20 at Week 16 was not part of the sequential
                testing procedure but was prespecified to be tested upon
                achieving statistical significance for ACR20 at Week 24.
              </b>
              <br />
              <sup>†</sup>The same patients may not have responded at each time
              point.
              <br />
              <sup>‡</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>§</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>‖</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results. <br />
              <sup>¶</sup>The prespecified as-observed analysis from Week 24 to
              Week 100 is not shown. <br /> <br />
              ACR20=American College of Rheumatology 20% improvement criteria;
              DMARD=disease-modifying antirheumatic drug; NRI=nonresponder
              imputation; PsA=psoriatic arthritis; q8w=every 8 weeks.
              <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. McInnes IB, et al. Poster presented at: Innovations in
              Dermatology 2021. 4. Data on file. Janssen Biotech, Inc.
            </div>
            <br />
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              ACR20 Response Through Week 52 (NRI)<sup>†‡§1-3</sup>
            </h4>
            {/* <img class="discover" src={discover1} alt="" /> */}
						<div className="img_wrapper">
              <img src={response16} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>ACR20 at Week 16 was not part of the sequential
                testing procedure but was prespecified to be tested upon
                achieving statistical significance for ACR20 at Week 24.
              </b>
              <br />
              <sup>†</sup>The same patients may not have responded at each time
              point.
              <br />
              <sup>‡</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>§</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>‖</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results. <br />
              <sup>¶</sup>The prespecified as-observed analysis from Weeks 24 to
              52 is not shown. <br /> <br />
              ACR20=American College of Rheumatology 20% improvement criteria;
              DMARD=disease-modifying antirheumatic drug; NRI=nonresponder
              imputation; PsA=psoriatic arthritis. <br /> <br />
              1. Deodhar A, et al.
              <i> Lancet</i>. 2020;395(10230):1115-1125. 2. Ritchlin CT, et al.{" "}
              <i>RMD Open</i>. 2021;7(1):e001457. 3. Data on file. Janssen
              Biotech, Inc.
            </div>
          </div>
        </div>
        {/* ) : null} */}
        {/* {Tab === 1 ? ( */}
        <div className={"tabcontent"} id="tab-1">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              ACR50 Response Through Week 100 (NRI)<sup>†‡§1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={response17} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>ACR50 at Weeks 16 and 24 was not part of the
                sequential testing procedure but was prespecified to be tested
                upon achieving statistical significance for ACR20 at Week 24.
              </b>
              <br />
              <sup>†</sup>The same patients may not have responded at each time
              point.
              <br />
              <sup>‡</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>§</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>‖</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results. <br />
              <sup>¶</sup>The prespecified as-observed analysis from Week 24 to
              Week 100 is not shown. <br /> <br />
              ACR50=American College of Rheumatology 50% improvement criteria;
              DMARD=disease-modifying antirheumatic drug; NRI=nonresponder
              imputation; PsA=psoriatic arthritis; q8w=every 8 weeks.
              <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. McInnes IB, et al. Poster presented at: Innovations in
              Dermatology 2021. 4. Data on file. Janssen Biotech, Inc.
            </div>
            <br />
            <h5 className={`${""}`}>In Adults With Active PsA</h5>

            <h4>
              ACR70 Response Through Week 100 (NRI)<sup>‡§‖1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={response18} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>ACR70 at Week 24 was not part of the sequential
                testing procedure but was prespecified to be tested upon
                achieving statistical significance for ACR20 at Week 24.
              </b>
              <br />
              <sup>†</sup>
              <b>
                ACR70 at Week 16 was not adjusted for multiplicity; therefore,
                statistical significance has not been established.
              </b>
              <br />
              <sup>‡</sup>The same patients may not have responded at each time
              point.
              <br />
              <sup>§</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied. </b>
              <br />
              <sup>‖</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>¶</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>#</sup>The prespecified as-observed analysis from Week 24 to
              Week 100 is not shown. <br /> <br />
              ACR70=American College of Rheumatology 70% improvement criteria;
              DMARD=disease-modifying antirheumatic drug; NRI=nonresponder
              imputation; PsA=psoriatic arthritis; q8w=every 8 weeks. <br />{" "}
              <br />
              <br />1 Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136.
              2. McInnes IB, et al. <i>Arthritis Rheumatol</i>.
              2021;73(4):604-616. 3. McInnes IB, et al. Poster presented at:
              Innovations in Dermatology 2021. 4. Data on file. Janssen Biotech,
              Inc.
            </div>
            <br />
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              ACR50 and 70 Response Through Week 52 (NRI)<sup>‡§‖1-3</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={response19} alt="" />
            </div>
						<div className="footnote">
              <sup>*</sup>
              <b>
                ACR50 at Weeks 16 and 24 and ACR70 at Week 24 were not part of
                the sequential testing procedure but were prespecified to be
                tested upon achieving statistical significance for ACR20 at Week
                24.
              </b>
              <br />
              <sup>†</sup>
              <b>
                ACR70 at Week 16 was not adjusted for multiplicity; therefore,
                statistical significance has not been established.
              </b>
              <br />
              <sup>‡</sup>The same patients may not have responded at each time
              point.
              <br />
              <sup>§</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>‖</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>¶</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>#</sup>The prespecified as-observed analysis from Weeks 24 to
              52 is not shown. <br />
              <br />
              ACR50/70=American College of Rheumatology 50/70% improvement
              criteria; DMARD=disease-modifying antirheumatic drug;
              NRI=nonresponder imputation; NS=not significant; PsA=psoriatic
              arthritis. <br /> <br />
              1. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125. 2.
              Ritchlin CT, et al. <i>RMD Open</i>. 2021;7(1):e001457. 3. Data on
              file. Janssen Biotech, Inc.
            </div>
          </div>
        </div>
        {/* ) : null} */}
      </div>
    </>
  );
};
