import React from "react";
import styles from "./TitleWithIcon.scss";

export default (props) => (
  <>
    <div className="" id={props.block.anchor}>
      {props.block.hr ? <hr className="TitleWithoutIconHR" /> : null}
      {props.block.headline ? (
				<div className="TitleWithIcon">
          <img className={props.block.icon} alt="" />
          <h3>{props.block.headline}</h3>
        </div>
      ) : null}
      {props.block.mainheadline ? (
				<div className="TitleWithoutIcon">
          <h3
            style={props.block.trueIfSpaceAbove ? { marginTop: "4rem" } : {}}
            dangerouslySetInnerHTML={{ __html: props.block.mainheadline }}
          ></h3>
        </div>
      ) : null}
    </div>
  </>
);
