import React, { useState, useEffect } from "react";
import "./TabEnthesitisDactylitis.scss";
import image1 from "../../assets/images/21-patients-experienced.png";
import image2 from "../../assets/images/22-majority-of-patients.png";

export default (props) => {
  const [Tab, setTab] = useState(0);

  const style0 = {
    backgroundImage:
      Tab === 0
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style1 = {
    backgroundImage:
      Tab === 1
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };

  useEffect(() => {
    document.getElementById(`tabtd-${Tab}`).classList.add("fade-in");
    document.getElementById(`tabtd-${Tab}`).classList.remove("fade-out");
		for (let i = 0; i <= 1; i++) {
			if (i != Tab) {
        document.getElementById("tabtd-" + i).classList.remove("fade-in");
        document.getElementById("tabtd-" + i).classList.add("fade-out");
      }
    }
  }, [Tab]);

  return (
    <>
      <div className="Tab" id="TabEnthesitisDactylitis">
        <div className={"tabLinksWrap"}>
          <button
            style={style0}
            className={"tabLinks"}
            onClick={() => setTab(0)}
          >
            <span className={"tabLinksText"}>Enthesitis</span>
          </button>
          <button
            style={style1}
            className={"tabLinks"}
            onClick={() => setTab(1)}
          >
            <span className={"tabLinksText"}>Dactylitis</span>
          </button>
        </div>

        <div className={"tabcontent"} id="tabtd-0">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              1 in 2 Patients Experienced Complete Resolution of Enthesitis at
              Week 24 (NRI)<sup>*†‡1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={image1} alt="" />
            </div>
						<div className="footnote">
              <sup>*</sup>Among patients with LEI enthesitis score &gt;0 at
              baseline. <br />
              <sup>†</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied. </b>
              <br />
              <sup>‡</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>§</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results. <br />
              <sup>‖</sup>The prespecified as-observed analyses at Week 52
              (pooled) and Week 100 (DISCOVER 2) are not shown. <br /> <br />
              DMARD=disease-modifying antirheumatic drug; LEI=Leeds Enthesitis
              Index; NRI=nonresponder imputation; PsA=psoriatic arthritis.
              <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. McInnes IB, et al. Poster presented at: Innovations in
              Dermatology 2021. 4. Data on file. Janssen Biotech, Inc.
            </div>
          </div>
        </div>
        <div className={"tabcontent"} id="tabtd-1">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}> In Adults With Active PsA</h5>
            <h4>
              The Majority of Patients Experienced Complete Resolution of
              Dactylitis at Week 24 (NRI)<sup>*†‡1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={image2} alt="" />
            </div>
						<div className="footnote">
              <sup>*</sup>Among patients with dactylitis at baseline. <br />
              <sup>†</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>‡</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup> §</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>‖</sup>The prespecified as-observed analyses at Week 52
              (pooled) and Week 100 (DISCOVER 2) are not shown. <br /> <br />
              DMARD=disease-modifying antirheumatic drug; NRI=nonresponder
              imputation; PsA=psoriatic arthritis. <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. McInnes IB, et al. Poster presented at: Innovations in
              Dermatology 2021. 4. Data on file. Janssen Biotech, Inc.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
