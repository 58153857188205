import React, { useState, useEffect } from "react";
import "./TabPasi.scss";
import haqdi30 from "../../assets/images/30-haq-di.png";
import mda31 from "../../assets/images/31-mda.png";
import mda32 from "../../assets/images/32-mda.png";
import baseline33 from "../../assets/images/33-baseline.png";
import improvement34 from "../../assets/images/34-improvement.png";
import discover2 from "../../assets/images/discover/discover-2.png";
import discover1 from "../../assets/images/discover/discover-1.png";

//
//create style
//create two buttons, one for each tab
export default (props) => {
  const [Tab, setTab] = useState(2);

  const style2 = {
    backgroundImage:
      Tab === 2
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style3 = {
    backgroundImage:
      Tab === 3
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style4 = {
    backgroundImage:
      Tab === 4
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };
  const style5 = {
    backgroundImage:
      Tab === 5
        ? "linear-gradient(to bottom, #003479, #003479)"
        : "linear-gradient(to bottom, #6ebd44, #359942)",
  };

  //make it so when tab changes element fades in and out
  useEffect(() => {
    document.getElementById(`tab-${Tab}`).classList.add("fade-in");
    document.getElementById(`tab-${Tab}`).classList.remove("fade-out");
    //loop through all document.getElementById("tab-" + i)")
		for (let i = 2; i <= 5; i++) {
			if (i != Tab) {
        document.getElementById("tab-" + i).classList.remove("fade-in");
        document.getElementById("tab-" + i).classList.add("fade-out");
      }
    }
  }, [Tab]);

  return (
    <>
      <div className="Tab " id="TabPasi">
        <div className={"tabLinksWrap"}>
          <button
            style={style2}
            className={"tabLinks"}
            onClick={() => setTab(2)}
          >
            <span className={"tabLinksText"}>HAQ-DI</span>
          </button>
          <button
            style={style3}
            className={"tabLinks"}
            onClick={() => setTab(3)}
          >
            <span className={"tabLinksText"}>MDA</span>
          </button>
          <button
            style={style4}
            className={"tabLinks"}
            onClick={() => setTab(4)}
          >
            <span className={"tabLinksText"}>FACIT</span>
          </button>
          <button
            style={style5}
            className={"tabLinks"}
            onClick={() => setTab(5)}
          >
            <span className={"tabLinksText"}>SF-36</span>
          </button>
        </div>

        <div className={"tabcontent"} id="tab-2">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              HAQ-DI Through Week 100 (NRI)<sup>†‡§1-4</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={haqdi30} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>Change from baseline in HAQ-DI score at Week 16
                endpoints were not adjusted for multiplicity; therefore,
                statistical significance has not been established.
              </b>
              <br />
              <sup>†</sup>Through Week 24, patients were considered to have no
              improvement (change=0) after meeting treatment failure criteria.
              Treatment failure criteria: Discontinued study agent injections
              due to lack of efficacy, terminated study participation for any
              reason, initiated or increased dose of DMARDs or oral
              corticosteroids over baseline for PsA, or initiated
              protocol-prohibited medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied. </b>
              <br />
              <sup>‡</sup>Multiple imputation was used to impute missing data.
              <br />
              <sup>§</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>‖</sup>The prespecified as-observed analyses from Week 24 to
              Week 100 in DISCOVER 2 and from Week 52 in DISCOVER 1 are not
              shown.
              <br /> <br />
              HAQ-DI=Health Assessment Questionnaire-Disability Index; LS=least
              squares; NRI=nonresponder imputation; PsA=psoriatic arthritis;
              q8w=every 8 weeks. <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              Data on file. Janssen Biotech, Inc. 3. Deodhar A, <br />
              et al. <i>Lancet</i>. 2020;395(10230):1115-1125. 4. Ritchlin CT,
              et al.
              <i> RMD Open</i>. 2021;7(1):e001457.
            </div>
          </div>
        </div>
        <div className={"tabcontent"} id="tab-3">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}> In Adults With Active PsA</h5>
            <h4>
              MDA at Weeks 24, 52, and 100 (NRI)<sup>*†1-3</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={mda31} alt="" />
            </div>
						<div className="footnote">
              <b>
                This endpoint was not adjusted for multiplicity; therefore,
                statistical significance has not been established.
              </b>
              <br />
              <sup>*</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b>
                {" "}
                After Week 24, treatment failure rules were not applied.
              </b>{" "}
              <br />
              <sup>†</sup>Patients with missing data were considered
              nonresponders. <br />
              <sup>‡</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results. <br />
              <sup>§</sup>The prespecified as-observed analysis at Week 52 and
              Week 100 is not shown. <br /> <br />
              BSA=body surface area; DMARD=disease-modifying antirheumatic drug;
              HAQ=Health Assessment Questionnaire; MDA=minimal disease activity;
              NRI=nonresponder imputation; PASI=Psoriasis Area and Severity
              Index; PsA=psoriatic arthritis; VAS=visual analog scale. <br />{" "}
              <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. McInnes IB, et al. Poster presented at: Innovations in
              Dermatology 2021. 4. Mease PJ, et al. <i>Arthritis Care & Res</i>.
              2011;63(S11):S64-S85.
            </div>
            <br />
            <h5 className={`${""}`}>In Adults With Active PsA</h5>
            <h4>
              MDA at Week 24 and Week 52 (NRI)<sup>*†1-3</sup>
            </h4>
            {/* <img class="discover" src={discover1} alt="" /> */}
						<div className="img_wrapper">
              <img src={mda32} alt="" />
            </div>
						<div className="footnote">
              <b>
                This endpoint was not adjusted for multiplicity; therefore,
                statistical significance has not been established.
              </b>
              <br />
              <sup>*</sup>Through Week 24, patients were considered to be
              nonresponders after meeting treatment failure criteria. Treatment
              failure criteria: Discontinued study agent injections due to lack
              of efficacy, terminated study participation for any reason,
              initiated or increased dose of DMARDs or oral corticosteroids over
              baseline for PsA, or initiated protocol-prohibited
              medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>†</sup>Patients with missing data were considered
              nonresponders.
              <br />
              <sup>‡</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>§</sup>The prespecified as-observed analysis at Week 52 is
              not shown. <br /> <br />
              BSA=body surface area; DMARD=disease-modifying antirheumatic drug;
              HAQ=Health Assessment Questionnaire; MDA=minimal disease activity;
              NRI=nonresponder imputation; PASI=Psoriasis Area and Severity
              Index; PsA=psoriatic arthritis; VAS=visual analog scale.
              <br /> <br />
              1. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125. 2.
              Ritchlin CT, et al.
              <i> RMD Open</i>. 2021;7(1):e001457. 3. Data on file. Janssen
              Biotech, Inc. 4. Mease PJ, et al. <i>Arthritis Care & Res</i>.
              2011;63(S11):S64-S85.
            </div>
          </div>
        </div>
        <div className={"tabcontent"} id="tab-4">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}> In Adults With Active PsA</h5>
            <h4>
              Mean Change From Baseline in FACIT-Fatigue at Weeks 24, 52, and
              100 (NRI)<sup>*†‡1,2</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={baseline33} alt="" />
            </div>
						<div className="footnote">
              <b>
                <sup>*</sup>FACIT-Fatigue was not adjusted for multiplicity;
                therefore, statistical significance has not been established.
              </b>
              <br />
              <sup>†</sup>Through Week 24, patients were considered to have no
              improvement (change=0) after meeting treatment failure criteria.
              Treatment failure criteria: Discontinued study agent injections
              due to lack of efficacy, terminated study participation for any
              reason, initiated or increased dose of DMARDs or oral
              corticosteroids over baseline for PsA, or initiated
              protocol-prohibited medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied.</b>
              <br />
              <sup>‡</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>§</sup>The prespecified as-observed analysis at Week 52 and
              Week 100 is not shown.
              <br />
              <sup>‖</sup>The change from baseline, if missing, was considered
              to have no improvement (change=0) after patients discontinued
              study treatment due to any reason. <br />
              <sup>¶</sup>Patients with data missing were considered
              nonresponders.
              <br /> <br />
              DMARD=disease-modifying antirheumatic drug;
              FACIT-Fatigue=Functional Assessment of Chronic Illness Therapy –
              Fatigue questionnaire; NRI=nonresponder imputation; PsA=psoriatic
              arthritis.
              <br /> <br />
              1. Helliwell P, et al. Presented at: EULAR 2020. Poster SAT0421.
              2. Data on file. Janssen Biotech, Inc.
            </div>
          </div>
        </div>
        <div className={"tabcontent"} id="tab-5">
          <div className="EyebrowHeadlineText">
            <h5 className={`${""}`}> In Adults With Active PsA</h5>
            <h4>
              Improvement in SF-36 PCS Score at Week 24 (NRI)<sup>*†1-5</sup>
            </h4>
            {/* <img class="discover" src={discover2} alt="" /> */}
						<div className="img_wrapper">
              <img src={improvement34} alt="" />
            </div>
						<div className="footnote">
              <b>
                There was not a statistically significant improvement observed
                in the SF-36 MCS.{" "}
              </b>{" "}
              <br />
              <b>
                In DISCOVER 1 and DISCOVER 2, a change from baseline in SF-36
                PCS score of ≥5 points was considered clinically meaningful.{" "}
              </b>{" "}
              <br />
              <sup>*</sup>Through Week 24, patients were considered to have no
              improvement (change=0) after meeting treatment failure criteria.
              Treatment failure criteria: Discontinued study agent injections
              due to lack of efficacy, terminated study participation for any
              reason, initiated or increased dose of DMARDs or oral
              corticosteroids over baseline for PsA, or initiated
              protocol-prohibited medications/therapies for PsA.
              <b> After Week 24, treatment failure rules were not applied. </b>
              <br />
              <sup>†</sup>Multiple imputation was used to impute missing data.{" "}
              <br />
              <sup>‡</sup>After Week 24, patients and doctors knew that all
              patients were on guselkumab (open label with a blinded dosing
              interval), which may affect the results.
              <br />
              <sup>§</sup>The prespecified as-observed analysis at Week 52 is
              not shown. <br /> <br />
              DMARD=disease-modifying antirheumatic drug; MCS=mental component
              summary; NRI=nonresponder imputation; PCS=Physical Component
              Summary; PsA=psoriatic arthritis; SF-36=36-Item Short Form Health
              Survey. <br /> <br />
              1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2.
              McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616.
              3. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125. 4.
              Data on file. Janssen Biotech, Inc. 5. Ritchlin CT, et al.{" "}
              <i>RMD Open</i>. 2021;7(1):e001457.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
