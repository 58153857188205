import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import Components from "./components.js";
import { isi } from "./isi/isi.js";
import { isiSafety } from "./isi/isi.js";
import { createRoot } from 'react-dom/client';

import "./styles.scss";
import ISIComponent from "./components/ISI/ISI.js";
import { useGTM } from "./components/GTM/useGTM";

const data = {
  content: {
    body: [
      {
        _uid: "-1",
        component: "TitleBlockBackground",
        headline: "Overview of Guselkumab:",
        text: ` <b>A Fully Human Anti–IL-23 in Active Psoriatic Arthritis and Moderate to Severe Plaque Psoriasis<br/><br/></b>`,
      },
      {
        _uid: "2",
        component: "EyebrowHeadlineText",
        // headline: "Guselkumab",
        text: "<b>Guselkumab was approved in 2017 for the treatment of adults with moderate-to-severe plaque psoriasis (PsO) who are candidates for systemic therapy or phototherapy, and was approved in 2020 for the treatment of adult patients with active psoriatic arthritis (PsA).</b>",
      },
      {
        _uid: "0",
        component: "Menu",
        headline: "Menu",
      },
      {
        // _uid: "11",
        component: "TitleWithIcon",
        headline:
          "Guselkumab: Treatment of Active Psoriatic Arthritis and Moderate to Severe Plaque Psoriasis",
        icon: "icon-gus",
        // hr: true,
        anchor: "Guselkumab",
      },
      {
        _uid: "4",
        component: "EyebrowHeadlineText",
        mainheadline:
          "The Role of IL-23 in Active Psoriatic Arthritis<sup>1-5</sup>",
        image: "slide-5",
        textUnderImage: `<br/>Although the exact pathogenesis is unknown, psoriatic arthritis is thought to share similar mechanisms involving the interleukin (IL)-23 Th-17 pathway as with psoriasis. However, years of research have revealed that plaque psoriasis and psoriatic arthritis are actually disorders of the immune system driven primarily by dysregulated T cells. <span class="make-inline">T cell-specific</span> pathways include regulatory cytokines such as interleukin 23 and interleukin 12, and effector cytokines such as interleukin 17A and interleukin 17F.<br/><br/>`,
      },
      {
        uid: "5",
        component: "EyebrowHeadlineText",
        headline: "",
        image: "slide-4",
        textUnderImage: `<br/>Studies suggest that the IL-23/Th-17 axis is one of the key pathways involved in the pathogenesis of plaque psoriasis and psoriatic arthritis. Looking more closely at <span class="make-inline">IL-23</span>, interaction of this cytokine with its receptor triggers the differentiation, proliferation, and survival of these T cells. The cytokines produced by these T cells stimulate inflammation in the skin, in patients with plaque psoriasis, and in the joints, skin, and entheses, in patients with psoriatic arthritis.`,

        footnotes: [
          `IL=interleukin; Th=T-helper cell.`,
          `<br/>1. Alunno A, et al.<i> Expert Opin Biol Ther</i>. 2015;15(12):1727-1737. 2. Lynde CW, et al. <i><i>J Am Acad Dermatol</i></i>. 2014;71(1):141-150. 3. Puig L. <i>Expert Rev Clin Immunol</i>. 2017;13(6):525-534. 4. de Vlam K, et al. <i>Acta Derm Venereol</i>. 2014;94(6):627-634. 5. Toussirot E, et al. <i>RMD Open.</i> 2018;4(e000821):1-7.`,
        ],
      },

      {
        _uid: "7",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "Guselkumab Mechanism of Action<sup>1-7</sup>",
        image: "guselkumab-moa-7",
        footnotes: [
          `<b><sup>*</sup>Other cytokines that facilitate differentiation of Th17 cells include TGF-β, IL-1, and IL-6, while IL-23 promotes survival and expansion of Th17 cells.<sup>7</sup></b>`,
          `<sup>†</sup>Serum levels of IL-17A, IL-17F, and IL-22 were reduced relative to pretreatment levels based on an exploratory analysis.`,
          `<br/>IL=interleukin; TGF-β=transforming growth factor; Th=T-helper cell.`,
          `<br/>1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. 2. Data on file. Janssen Biotech, Inc. 3. Zhuang Y, et al. <i>Eur J Clin Pharmacol</i>. 2016;72(11):1303-1310. 4. Nestle FO, et al. <i>N Engl J Med</i>. 2009;361:496-509. 5. Fitch E, et al. <i>Curr Rheumatol Rep</i>. 2007;9(6):461-467. 6. Fujita H. <i>J Dermatol Sci</i>. 2013;72(1):3-8. 7. Boutet MA, et al. <i>Int J Mol Sci</i>. 2018;19:530. doi:10.3390/ijms19020530.`,
        ],
      },
      {
        uid: "6",
        component: "EyebrowHeadlineText",

        textUnderImage: `Guselkumab is a human monoclonal IgG1λ antibody that selectively binds to the p19 subunit of IL-23 and inhibits its interaction with the IL-23 receptor. IL-23 is a naturally occurring cytokine that is involved in normal inflammatory and immune responses. Guselkumab inhibits the release of proinflammatory cytokines and chemokines.


        <br/> <br/>
        In subjects with plaque psoriasis, guselkumab reduced serum levels of IL-17A, IL-17F, and IL-22 relative to pretreatment levels.
        <br/> <br/>
        In subjects with psoriatic arthritis, serum levels of acute phase proteins C-reactive protein, serum amyloid A and IL-6, and Th17 effector cytokines IL-17A, IL-17F, and IL-22 were elevated at baseline. Serum levels of these proteins measured at Week 4 and Week 24 were decreased compared to baseline following guselkumab treatment at Week 0, Week 4\, and every 8 weeks thereafter.


        `,
        footnotes: [
          `IL=interleukin; Th=T-helper cell.`,
          `  1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc.`,
        ],
      },

      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        // trueIfSpaceAbove: true,
        // hr: true,
        hrTop: true,
        mainheadline:
          "Guselkumab in the Treatment of Active Psoriatic Arthritis",
      },
      {
        // _uid: "11",
        component: "TitleWithIcon",
        headline: "DISCOVER Trial Designs",
        icon: "icon-discover",
        // hr: true,
        // hrTop: true,
        trueIfNoSpaceAbove: true,

        anchor: "Discover",
      },

      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "DISCOVER 1 and DISCOVER 2 Overview",
        textTop: `Guselkumab has been studied in two pivotal phase 3 trials in patients with active psoriatic arthritis. Patients in DISCOVER 2 were bio-naïve whereas patients in <br/>DISCOVER 1 were permitted to have had previous tumor necrosis factor (TNF)-α inhibitor treatment. Results from the DISCOVER 1 and DISCOVER 2 studies, including efficacy and safety data from DISCOVER 2 through 2 years (Week 100), is presented below.
          `,
      },
      {
        _uid: "11",
        component: "EyebrowHeadlineText",
        trueIfNoSpaceAbove: true,
        h4BottomZero: true,
        trueIfPaddingBelowContainer: true,

        eyebrow: "In Adults With Active PsA",
        headline: "DISCOVER 2: Study Design<sup>*1,2</sup>",
        discover: "N739",
        textUnderDiscover: `<b style="color:#003479;">DISCOVER 2</b> was a phase 3, multicenter, randomized, double-blind, placebo-controlled study evaluating the efficacy and safety of guselkumab administered subcutaneously in bio-naïve patients with active PsA (fulfilling CASPAR criteria) despite standard therapies (nonbiologic DMARDs, apremilast, and NSAIDs). A stable dose of 1 selected nonbiologic DMARD, corticosteroids, and NSAIDs was permitted but not required. </br></br><b>Selected Inclusion and Exclusion Criteria</b>`,
        bullets: [
          `≥18 years of age
`,
          `Active PsA (swollen/tender joints ≥5, CRP ≥0.6 mg/dL) for at least 6 months
`,
          `No prior biologic experience
`,
          `Previous exposure to biologics or Janus kinase inhibitors precluded participation
`,
        ],
        image: "study-design-11",
        footnotes: [
          `<sup>*</sup>In DISCOVER 2, 245 patients were originally randomized to a q4w dosing regimen. Guselkumab 100 mg q4w is not an approved dosing regimen.`,
          `<sup>†</sup>At Week 24, patients taking placebo crossed over to receive guselkumab 100 mg every 4 weeks thereafter. Guselkumab 100 mg dosed every 4 weeks is not an approved dosing regimen.`,
          `<sup>‡</sup>At Week 16, subjects in all treatment groups who had <5% improvement from baseline in both swollen and tender joint counts were considered as meeting early escape criteria and were allowed to initiate or increase the dose of one of the permitted concomitant medications up to the maximum dose allowed.<br/>`,
          `<br/>ACR20=American College of Rheumatology 20% improvement criteria; CASPAR=Classification Criteria for Psoriatic Arthritis; CRP=C-reactive protein; DMARD=disease-modifying antirheumatic drug; NSAID=nonsteroidal anti-inflammatory drugs; PsA=psoriatic arthritis; q4w=every 4 weeks.`,
          `<br/>1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136.  2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "12",
        component: "EyebrowHeadlineText",
        headline: "DISCOVER 1: Study Design<sup>*1,2</sup>",
        discover: "N381",
        h4BottomZero: true,
        textUnderDiscover: `<b style="color:#003479;">DISCOVER 1</b> was a phase 3, multicenter, randomized, double-blind, placebo-controlled study evaluating the efficacy and safety of guselkumab administered subcutaneously in patients with active PsA (fulfilling CASPAR criteria) despite standard therapies (nonbiologic DMARDs, apremilast, and NSAIDs) or previous TNF-α inhibitor treatment. A stable dose of 1 selected nonbiologic DMARD, corticosteroids, and NSAIDs was permitted but not required.</br></br><b>Selected Inclusion and Exclusion Criteria</b>`,
        bullets: [
          `≥18 years of age
`,
          `Active PsA (swollen/tender joints ≥3, CRP ≥0.3 mg/dL) for at least 6 months

`,
          `Prior biologic experience of ≤2 anti-TNFs (31%)

`,
          `Patients with other inflammatory diseases and those who had previously received JAK inhibitors or biologics other than TNF inhibitors were excluded

`,
        ],
        image: "study-design-12",
        footnotes: [
          `<sup>*</sup>In DISCOVER 1, 128 patients were originally randomized to a q4w dosing regimen. Guselkumab 100 mg q4w is not an approved dosing regimen. `,
          `<sup>†</sup>At Week 24, patients taking placebo crossed over to receive guselkumab 100 mg every 4 weeks thereafter. Guselkumab 100 mg dosed every 4 weeks is not an approved dosing regimen.  `,
          `<sup>‡</sup>At Week 16, subjects in all treatment groups who had <5% improvement from baseline in both swollen and tender joint counts were considered as meeting early escape criteria and were allowed to initiate or increase the dose of one of the permitted concomitant medications up to the maximum dose allowed.  `,
          `<br/>ACR20=American College of Rheumatology 20% improvement criteria; CASPAR=Classification Criteria for Psoriatic Arthritis; CRP=C-reactive protein; DMARD=disease-modifying antirheumatic drug; JAK=Janus kinase; NSAID=nonsteroidal anti-inflammatory drugs; PsA=psoriatic arthritis; q4w=every 4 weeks; TNF=tumor necrosis factor.`,
          `     <br/>1. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125.  2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "13",
        component: "EyebrowHeadlineText",
        eyebrow: "In Adults With Active PsA",
        mainheadline: `Baseline Characteristics`,
        trueIfSpaceAbove: true,
        trueIfNoSpaceAbove: true,
        headline: "Key Baseline Characteristics",
        image: "baseline-char-13",
        footnotes: [
          `<br/>BMI=body mass index; BSA=body surface area; CRP=C-reactive protein; HAQ-DI=Health Assessment Questionnaire-Disability Index; IGA=Investigator's Global Assessment of psoriasis assessed as: cleared (0), minimal (1), mild (2), moderate (3), or severe (4); LEI=Leeds Enthesitis Index; PASI=Psoriasis Area and Severity Index; PsA=psoriatic arthritis; SD=standard deviation; TNF=tumor necrosis factor.   `,
          `<br/>1. Data on file. Janssen Biotech, Inc. 2. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 3. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125. `,
        ],
      },
      {
        _uid: "14",
        component: "TitleWithIcon",
        headline: "Efficacy of Guselkumab in Active Psoriatic Arthritis",
        icon: "icon-efficacy",
        hr: true,
        anchor: "Efficacy",
      },
      {
        _uid: "14",
        component: "TitleWithIcon",
        mainheadline: "DISCOVER 1 and DISCOVER 2 Efficacy<br/><br/>",
      },
      {
        _uid: "15",
        component: "Tab",
      },
      {
        _uid: "20",
        component: "TitleWithIcon",
        mainheadline: "Enthesitis & Dactylitis",
      },
      {
        _uid: "21",
        component: "TabEnthesitisDactylitis",
      },

      {
        _uid: "23",
        component: "TitleWithIcon",
        mainheadline: "Skin Manifestations",
      },
      {
        _uid: "24",
        component: "EyebrowHeadlineText",
        trueIfPaddingBelowContainer: true,

        eyebrow: "In Adults With Active PsA ",
        headline:
          "Improvement in the Skin Manifestations of Psoriasis at Week 24 (NRI)<sup>†‡1-4</sup>",
        image: "manifestations-24",
        footnotes: [
          `<sup>*</sup>IGA 0/1=Proportion of patients who achieved an IGA score of cleared (0) or minimal (1) using a 5-point scale where psoriatic lesions are graded by the investigator for induration, erythema, and scaling on a scale of 0 to 4: Cleared, except for residual discoloration (0), minimal (1), mild (2), moderate (3), or severe (4).`,
          `<sup>†</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied.    </b>`,
          `<sup>‡</sup>Patients with missing data were considered nonresponders. `,
          `<sup>§</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results.`,
          `<sup> ‖</sup>The prespecified as-observed analysis at Week 52 and Week 100 is not shown. `,
          `<br/> BSA=body surface area; DMARD=disease-modifying antirheumatic drug; IGA=Investigator’s Global Assessment; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `<br/> 1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2. McInnes IB, et al. <i>Arthritis Rheumatol</i>. 2021;73(4):604-616. 3. McInnes IB, et al. Poster presented at: Innovations in Dermatology 2021. 4. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "25",
        component: "EyebrowHeadlineText",
        eyebrow: "In Adults With Active PsA ",
        trueIfPaddingBelowContainer: true,

        headline:
          "Improvement in the Skin Manifestations of Psoriasis at Week 24 (NRI)<sup>†‡1-3</sup>",
        image: "manifestations-25",
        footnotes: [
          `<sup>*</sup>IGA 0/1=Proportion of patients who achieved an IGA score of cleared (0) or minimal (1) using a 5-point scale where psoriatic lesions are graded by the investigator for induration, erythema, and scaling on a scale of 0 to 4: cleared, except for residual discoloration (0), minimal (1), mild (2), moderate (3), or severe (4).`,
          `<sup>†</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied.  </b> `,
          `   <sup>‡</sup>Patients with missing data were considered nonresponders. `,
          `   <sup>§</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results.`,
          `  <sup>‖</sup>The prespecified as-observed analysis at Week 52 is not shown. `,
          `   <br/>BSA=body surface area; DMARD=disease-modifying antirheumatic drug; IGA=Investigator’s Global Assessment; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `  <br/> 1. Deodhar A, et al. <i>Lancet</i>. 2020;395(10230):1115-1125. 2. Ritchlin CT, et al. <i>RMD Open</i>. 2021;7(1):e001457. 3. Data on file. Janssen Biotech, Inc. `,
        ],
      },
      {
        _uid: "26",
        component: "TitleWithIcon",
        mainheadline: "PASI 90",
        trueIfSpaceAbove: true,
      },
      {
        _uid: "27",
        component: "EyebrowHeadlineText",
        eyebrow: "In Adults With Active PsA ",
        trueIfPaddingBelowContainer: true,

        headline:
          "PASI 90 Response Rates at Weeks 24, 52, and 100 (NRI)<sup>*†‡¶1,2</sup>",
        image: "rates-27",
        trueIfNoSpaceAbove: true,
        footnotes: [
          `<b><sup>*</sup>This endpoint was not adjusted for multiplicity; therefore, statistical significance has not been established.</b> `,
          `   <sup>†</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `   <sup> ‡</sup>Patients with missing data were considered nonresponders. `,
          `   <sup>   §</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results. `,
          `              <sup>   ‖</sup>The prespecified as-observed analysis at Week 52 and Week 100 is not shown.`,
          `   <sup>  ¶</sup>Patients with missing data were considered nonresponders. `,
          ` <br/>  BSA=body surface area; DMARD=disease-modifying antirheumatic drug; IGA=Investigator Global Assessment; NRI=nonresponder imputation; PASI=Psoriasis Area and Severity Index;  PsA=psoriatic arthritis.`,
          ` <br/>  1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "28",
        component: "EyebrowHeadlineText",
        eyebrow: "In Adults With Active PsA ",
        trueIfPaddingBelowContainer: true,

        headline:
          "PASI 90 Response Rates at Weeks 24 and 52 (NRI)<sup>*†‡1,2</sup>",
        image: "rates-28",
        footnotes: [
          ` <b><sup>*</sup>This endpoint was not adjusted for multiplicity; therefore, statistical significance has not been established.</b> `,
          `<sup>†</sup>Patients with missing data were considered nonresponders. `,
          `<sup>‡</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `<sup>§</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results. `,
          `<sup>‖</sup>The prespecified as-observed analysis at Week 52 is not shown. `,
          `<br/>BSA=body surface area; DMARD=disease-modifying antirheumatic drug; IGA=Investigator Global Assessment; NRI=nonresponder imputation; PASI=Psoriasis Area and Severity Index; PsA=psoriatic arthritis.`,
          `<br/>1. Mease PJ, et al. <i>Lancet</i>. 2020;395(10230):1126-1136. 2. Data on file. Janssen Biotech, Inc. <br/> <br/><br/>`,
        ],
      },

      {
        _uid: "29",
        component: "TitleWithIcon",
        mainheadline: "Additional Endpoints<br/><br/>",
      },

      {
        _uid: "30",
        component: "TabPasi",
      },
      {
        hr: true,
        _uid: "29",
        component: "TitleWithIcon",
        headline:
          "Efficacy of Guselkumab: Axial Involvement in Active Psoriatic Arthritis and Sacroiliitis ",

        icon: "icon-axial",
        hr: true,
        // mainheadline: "Additional Endpoints",
        // <h3>{props.block.mainheadline}</h3>
        anchor: "Axial",
      },
      {
        _uid: "36",
        component: "EyebrowHeadlineText",
        trueIfNoSpaceAbove: true,
        eyebrow: "In Adults With Active PsA ",
        trueIfPaddingBelowContainer: true,

        mainheadline: "BASDAI",
        headline: "BASDAI at Weeks 24, 52, and 100 (NRI)<sup>*†‡§#1,2</sup>",
        image: "basdai-36",
        footnotes: [
          `<b><sup>*</sup>These endpoints were not adjusted for multiplicity; therefore, statistical significance has not been established. </b>`,
          `  <sup>†</sup>Subsets of DISCOVER 1 and DISCOVER 2 patients with sacroiliitis at baseline who had either documented imaging confirmation of sacroiliitis in the past or pelvic X-ray confirmation of sacroiliitis at screening based on investigators’ judgment of presence/absence of sacroiliitis are included.`,
          `    <sup>‡</sup>BASDAI is a subject self-assessment of axial disease that asks patients to rate their symptoms on a scale of 0 to 10, with higher scores indicating greater disease severity. `,
          `    <sup>§</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `    <sup>¶</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results.`,
          `    <sup>‖</sup>The prespecified as-observed analyses at Week 52 (pooled) and Week 100 (DISCOVER 2) are not shown. `,
          `   <sup>#</sup>LS mean changes for BASDAI scores were calculated using Mixed-Effect Repeated Measures. Patients with missing data were considered nonresponders.  `,
          `     <br/>BASDAI=Bath Ankylosing Spondylitis Disease Activity Index; DMARD=disease-modifying antirheumatic drug; LS=least squares; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `    <br/> 1. Mease PJ, et al. Presented at: ACR 2020. OP2025. 2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "37",
        component: "EyebrowHeadlineText",
        eyebrow: "In Adults With Active PsA",
        trueIfPaddingBelowContainer: true,

        headline:
          "Mean Change From Baseline in BASDAI Components at Week 24 (NRI)<sup>*†‡§‖1</sup>",
        image: "basdai-37",
        footnotes: [
          `<b><sup>*</sup>This endpoint was not adjusted for multiplicity; therefore, statistical significance has not been established.</b> `,
          `<sup>†</sup>Subsets of DISCOVER 1 and DISCOVER 2 patients with sacroiliitis at baseline who had either documented imaging confirmation of sacroiliitis in the past or pelvic X-ray confirmation of sacroiliitis at screening based on investigators’ judgment of presence/absence of sacroiliitis are included.`,
          `<sup>‡</sup>BASDAI is a subject self-assessment of axial disease that asks patients to rate their symptoms on a scale of 0 to 10; with higher scores indicating greater disease severity. `,
          `<sup>§</sup>Through Week 24, patients were considered to have no improvement (change=0) or to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `<sup>‖</sup>LS mean changes for BASDAI scores were calculated using Mixed-Effect Repeated Measures. Patients with missing data were considered nonresponders.  `,
          `<br/>BASDAI=Bath Ankylosing Spondylitis Disease Activity Index; DMARD=disease-modifying antirheumatic drug; LS=least squares; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `<br/>1. Data on file. Janssen Biotech, Inc.`,
        ],
      },
      {
        _uid: "38",
        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Active PsA `,
        headline: `BASDAI 50 at Weeks 24, 52, and 100 (NRI)<sup>*†‡§#1,2</sup>

         `,
        image: `basdai50-38`,
        footnotes: [
          `<b><sup>*</sup>These endpoints were not adjusted for multiplicity; therefore, statistical significance has not been established.</b> `,
          `<sup>†</sup>Subsets of DISCOVER 1 and DISCOVER 2 patients with sacroiliitis at baseline who had either documented imaging confirmation of sacroiliitis in the past or pelvic X-ray confirmation of sacroiliitis at screening based on investigators’ judgment of presence/absence of sacroiliitis are included.`,
          `<sup>‡</sup>BASDAI is a subject self-assessment of axial disease that asks patients to rate their symptoms on a scale of 0 to 10, with higher scores indicating greater disease severity.`,
          `<sup>§</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment Failure Criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `<sup>‖</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results.`,
          `<sup>¶</sup>The prespecified as-observed analyses at Week 52 (pooled) and Week 100 (DISCOVER 2) are not shown. `,
          `<sup>#</sup>Patients with missing data were considered nonresponders.`,
          `<br />BASDAI=Bath Ankylosing Spondylitis Disease Activity Index; DMARD=disease-modifying antirheumatic drug; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `<br/>1. Mease PJ, et al. Presented at: ACR 2020. OP2025.  2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "39",
        component: "TitleWithIcon",
        mainheadline: "ASDAS-CRP",
        trueIfSpaceAbove: true,
      },
      {
        _uid: "40",
        component: `EyebrowHeadlineText`,
        trueIfNoSpaceAbove: true,
        eyebrow: `In Adults With Active PsA `,
        headline: `ASDAS-CRP at Weeks 24, 52, and 100 (NRI)<sup>*†‡¶1,2</sup>

         `,
        image: `crp-asdas-40`,
        footnotes: [
          `<b><sup>*</sup>These endpoints were not adjusted for multiplicity; therefore, statistical significance has not been established.</b> `,
          `<sup>†</sup>Included patients with sacroiliitis at baseline who had either documented imaging confirmation of sacroiliitis in the past or pelvic X-ray confirmation of sacroiliitis at screening based on investigators’ judgment of presence/absence of sacroiliitis.`,
          `<sup>‡</sup>Through Week 24, patients were considered to be nonresponders after meeting treatment failure criteria. Treatment failure criteria: Discontinued study agent injections due to lack of efficacy, terminated study participation for any reason, initiated or increased dose of DMARDs or oral corticosteroids over baseline for PsA, or initiated protocol-prohibited medications/therapies for PsA. <b>After Week 24, treatment failure rules were not applied. </b>`,
          `<sup>§</sup>After Week 24, patients and doctors knew that all patients were on guselkumab (open label with a blinded dosing interval), which may affect the results.`,
          `<sup>‖</sup>The prespecified as-observed analyses at Week 52 (pooled) and Week 100 (DISCOVER 2) are not shown. `,
          `<sup>¶</sup>LS mean changes for ASDAS scores were calculated using Mixed-Effect Repeated Measures. Patients with missing data were considered nonresponders.  `,
          `<br/>ASDAS=Ankylosing Spondylitis Disease Activity Score; CRP=C-reactive protein; DMARD=disease-modifying antirheumatic drug; LS=least squares; NRI=nonresponder imputation; PsA=psoriatic arthritis.`,
          `<br/>1. Mease PJ, et al. Presented at: ACR 2020. OP2025.  2. Data on file. Janssen Biotech, Inc.  `,
        ],
      },
      {
        _uid: "41",
        component: "TitleWithIcon",
        headline: "Guselkumab Safety Profile",
        icon: "icon-safety",
        hr: true,
        anchor: "Safety",
      },
      {
        _uid: "41",
        component: "TitleWithIcon",
        mainheadline: "DISCOVER 1 and DISCOVER 2 Safety Data Through 1 Year",
      },
      {
        _uid: "42",
        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Active PsA `,
        trueIfPaddingBelowContainer: true,

        headline: `DISCOVER 1 and DISCOVER 2 Safety Profile in Active Psoriatic Arthritis<sup>*1,2</sup>


         `,
        imageTop: `safety-42`,
        // text: `The overall safety profile observed in patients with psoriatic arthritis treated with guselkumab is generally consistent with the safety profile in patients with plaque psoriasis, with the addition of bronchitis and neutrophil count decreased. In the 24-week placebo-controlled period, combined across the two studies:  `,
        textAboveBullets: `The overall safety profile observed in patients with psoriatic arthritis treated with guselkumab is generally consistent with the safety profile in patients with plaque psoriasis, with the addition of bronchitis and neutrophil count decreased. In the 24-week placebo-controlled period, combined across the two studies:  `,

        bullets: [
          `Bronchitis occurred in 1.6% of patients in the guselkumab q8w group and in 1.1% of patients in the placebo group `,
          `Neutrophil count decreased occurred in 0.3% of patients in the guselkumab q8w group compared to 0% of patients in the placebo group. The majority of neutrophil count decreases were mild, transient, not associated with infection, and did not lead to discontinuation   `,
        ],

        footnotes: [
          `<sup>*</sup>One year defined as 60 weeks (through end of study) in DISCOVER 1 and 52 weeks in DISCOVER 2.`,
          `<br/>  PsA=psoriatic arthritis; q8w=every 8 weeks.`,
          ` <br/> 1. Data on file. Janssen Biotech, Inc.  2. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc`,
        ],
      },
      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: `DISCOVER 2 Safety Profile Through 2 Years`,
      },
      {
        _uid: "43",
        trueIfPaddingBelowContainer: true,

        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Active PsA `,
        headline: `DISCOVER 2 Safety Profile in Active Psoriatic Arthritis Through 2 Years (Week 112)<sup>1,2</sup>     `,
        imageTop: `safety-43`,
        footnotes: [
          `PsA=psoriatic arthritis; q8w=every 8 weeks.`,
          `  1. Data on file. Janssen Biotech, Inc.  2. McInnes IB, et al. Poster presented at: Innovations in Dermatology 2021. `,
        ],
      },
      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: `Adverse Events Through 2 Years`,
      },
      {
        _uid: "44",
        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Active PsA `,
        trueIfPaddingBelowContainer: true,

        headline: `Adverse Events of Interest by Year of Guselkumab Exposure Through 2 Years (Week 112)<sup>*1-4</sup>    `,
        imageTop: `exposure-44`,
        footnotes: [
          `<b>Serious hypersensitivity reactions, including anaphylaxis, have been reported with postmarket use of guselkumab. Some cases required hospitalization.</b>`,
          `<sup>*</sup>Open label active treatment represents different pools of patients with varying lengths of treatment exposure. `,
          `<sup>†</sup>One year defined as 60 weeks (through end of study) in DISCOVER 1 and 52 weeks in DISCOVER 2. `,
          `<br/>IBD=inflammatory bowel disease; MACE=major adverse cardiovascular events; MI=myocardial infarction; NMSC=nonmelanoma skin cancer; PsA=psoriatic arthritis; q8w=every 8 weeks; TB=tuberculosis.`,
          ` <br/> 1. Data on file. Janssen Biotech, Inc. 2. Ritchlin CT, et al. Poster presented at: AAD VMX 2021. 3. McInnes IB, et al. Poster presented at: Innovations in Dermatology 2021. 4. Foley P, et al. Poster presented at: Innovations in Dermatology 2021.    `,
        ],
      },
      {
        _uid: "45",
        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Active PsA `,
        trueIfPaddingBelowContainer: true,

        headline: `<i>Candida</i> Infections by Year of Guselkumab Exposure Through 2 Years (Week 112)<sup>1</sup>   `,
        imageTop: `exposure-45`,
        footnotes: [
          `<sup>*</sup>One year defined as 60 weeks (through end of study) in DISCOVER 1 and 52 weeks in DISCOVER 2. `,
          `<br/> HLT=high-level term; PsA=psoriatic arthritis; PT=preferred term; q8w=every 8 weeks.`,
          ` <br/> 1. Data on file. Janssen Biotech, Inc.`,
        ],
      },
      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        // trueIfSpaceAbove: true,
        // hr: true,
        hrTop: true,
        mainheadline: `Guselkumab in the Treatment of Moderate to Severe Plaque Psoriasis`,
      },
      {
        // _uid: "11",
        component: "TitleWithIcon",
        headline: "VOYAGE Trial Designs",
        icon: "icon-voyage",

        anchor: "Voyage",
      },

      {
        _uid: "46",
        component: "TitleWithIcon",
        mainheadline: "VOYAGE 1 and VOYAGE 2 Overview",
      },
      {
        _uid: "47",
        component: `EyebrowHeadlineText`,
        bullets: [
          `VOYAGE 1 (NCT02207231) (N=837) and VOYAGE 2 (NCT02207244) (N=992) were randomized, double-blind, placebo- and active-comparator–controlled studies of guselkumab`,
          `In VOYAGE 1 and VOYAGE 2, eligible patients (aged ≥18 years) had moderate to severe plaque psoriasis (ie, IGA score ≥3, PASI score ≥12, body surface area involvement ≥10%) for at least 6 months and were candidates for systemic therapy or phototherapy`,
        ],
        footnotes: [
          `<br/>IGA=Investigator's Global Assessment; PASI=Psoriasis Area and Severity Index.`,
          `TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. `,
        ],
      },
      {
        _uid: "48",
        component: `EyebrowHeadlineText`,
        eyebrow: `<br/>In Adults With Moderate to Severe Plaque Psoriasis `,
        trueIfPaddingBelowContainer: true,

        headline: `VOYAGE 1: Phase 3 Multicenter, Double-blind Trial (n=837)<sup>1-4</sup>

        `,
        text: `<b>Data Handling Rules<sup>4</sup></b><br/>
        <b>NRI:</b> Patients with missing efficacy data (regardless of reasons) after application of TFR were counted as nonresponders (binary endpoints). <br/>
        <b>TFR:</b> Patients who discontinued study agent due to lack of efficacy or an adverse event of worsening psoriasis, or who started a protocol-prohibited psoriasis treatment, were considered nonresponders. During the open label extension, with the exception of ultra‒high-potency corticosteroids, other topical agents for psoriasis were permitted. <br/>
        <b>AO:</b> Available data at each visit were used. Missing data were not included in the analysis.
        `,
        imageTop: `multicenter-48`,
        footnotes: [
          `<sup>*</sup>After Week 48, an open label treatment period began where patients initially randomized to guselkumab or placebo at Week 0 continued to receive guselkumab 100 mg every 8 weeks at Week 52 through the extension period. Patients initially randomized to adalimumab entered a washout period after their final dose of adalimumab at Week 47 and initiated guselkumab 100 mg at Week 52 and then every 8 weeks thereafter. `,
          `<sup>†</sup>The last dose of guselkumab was administered at Week 252; efficacy was evaluated through Week 252.`,
          `<sup>‡</sup>Safety was evaluated through Week 264.`,

          `<br/>AO=as observed; BSA=body surface area; IGA=Investigator’s Global Assessment; NRI=nonresponder imputation; TFR=treatment failure rules.`,
          `<br/>1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. 2. Blauvelt A, et al. <i>J Am Acad Dermatol</i>. 2017;76(3):405-417. 3. Data on file. Janssen Biotech, Inc. 4. Griffiths CEM, et al. Poster presented at: 2020 Coastal Dermatology Symposium.
           `,
        ],
      },
      {
        _uid: "49",
        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Moderate to Severe Plaque Psoriasis `,
        trueIfPaddingBelowContainer: true,

        headline: `VOYAGE 2: Phase 3 Multicenter, Double-blind Trial (n=992)<sup>1-4</sup>


        `,
        text: `<b>Data Handling Rules</b> </br>
        Analyses were NRI. Patients with missing efficacy data (regardless of reasons) after application of TFR were counted as nonresponders (binary endpoints).

        `,
        imageTop: `multicenter-49`,
        footnotes: [
          `<sup>*</sup>Upon loss of ≥50% of improvement in PASI achieved at Week 28 or at Week 72 if prerequisite loss of PASI improvement was not observed, then reinitiate or initiate guselkumab.`,
          `<sup>†</sup>The last dose of guselkumab was administered at Week 252; efficacy was evaluated through Week 252.`,
          `<sup>‡</sup>Safety was evaluated through Week 264.`,
          `<br/>IGA=Investigator’s Global Assessment; NRI=nonresponder imputation; PASI=Psoriasis Area and Severity Index; q8w=every 8 weeks; TFR=treatment failure rules.`,
          `<br/>1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. 2. Reich K, et al. <i>J Am Acad Dermatol</i>. 2017;76(3):418‑431. 3. Data on file. Janssen Biotech, Inc.  4. Reich K, et al. <i>Br J Dermatol</i>.                                                             2021;185(6):1146-1159.

           `,
        ],
      },
      {
        _uid: "52",
        component: "TitleWithIcon",
        mainheadline:
          "Efficacy of Guselkumab in Moderate to Severe Plaque Psoriasis<br/><br/>",
        trueIfSpaceAbove: true,
      },
      { component: "TabVoyage" },
      {
        _uid: "52",
        component: "TitleWithIcon",
        mainheadline: `Guselkumab Safety Profile in Moderate to Severe Plaque Psoriasis`,
      },
      {
        _uid: "56",
        component: `EyebrowHeadlineText`,
        trueIfPaddingBelowContainer: true,

        eyebrow: `In Adults With Moderate to Severe Plaque Psoriasis`,
        headline: `VOYAGE 1 and VOYAGE 2 Pooled Safety Data* Through Week 16<sup>†1,2</sup>

        `,
        imageTop: `pooled-56`,
        footnotes: [
          ` <sup>*</sup>Data from 2 placebo- and active-controlled trials (VOYAGE 1 and VOYAGE 2) in 1441 patients (mean age 44 years; 70% males; 82% white) were pooled to evaluate the safety of guselkumab (100 mg administered subcutaneously at Weeks 0 and 4, followed by q8w). This safety information does not include data from NAVIGATE, ECLIPSE, ORION, DISCOVER 1, or DISCOVER 2 studies.`,
          ` <sup>†</sup>Through Week 48, no new adverse reactions were identified with Guselkumab use and the frequency of the adverse reactions was similar to the safety profile observed during the first 16 weeks of treatment. `,
          ` <sup>‡</sup>All cases were mild to moderate in severity and did not lead to discontinuation of guselkumab.`,
          ` <sup>§</sup>Adverse reactions occurring in ≥1% of patients and at a higher rate than placebo through Week 16 in VOYAGE 1 and VOYAGE 2. Adverse reactions that occurred in <1% but >0.1% of the patients in the guselkumab group and at a higher rate than in the placebo group through Week 16 in VOYAGE 1 and VOYAGE 2 were migraine, <i>Candida</i> infections, and urticaria. `,
          ` <sup>‖</sup>US-licensed Humira<sup>®</sup>. `,
          `<br/>q8w=every 8 weeks; URTI=upper respiratory tract infection.`,
          `<br/>  1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc.  2. Data on file. Janssen Biotech, Inc. `,
        ],
      },
      {
        _uid: "57",
        trueIfPaddingBelowContainer: true,

        component: `EyebrowHeadlineText`,
        eyebrow: `In Adults With Moderate to Severe Plaque Psoriasis`,
        headline: `Pooled Safety Data by Year of Guselkumab Exposure Through 5 Years (Week 264)<sup>1,2</sup>`,
        imageTop: `pooled-safety-57`,
        footnotes: [
          `<sup>*</sup>Safety summary includes all patients exposed to guselkumab.`,
          `<sup>†</sup>In the open label extension, an event was included in Year 1 if it occurred within 52 weeks after the first guselkumab administration; in Year 2 if it occurred between 52 weeks and 104 weeks after the first guselkumab administration; in Year 3 if it occurred between 104 weeks and 156 weeks after the first guselkumab administration; in Year 4 if it occurred between 156 weeks and 208 weeks after the first guselkumab administration; in Year 5 if it occurred beyond 208 weeks after the first guselkumab administration.  `,
          `<br/>1. Data on file. Janssen Biotech, Inc. 2. Blauvelt A, et al. Poster presented at: AAD VMX 2021.`,
        ],
      },
      {
        _uid: "10",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        textTop: `<div style="text-align:center;"><br/><br/><br/><br/><span style="font-size:12px"><b>Please read the full <a target="_blank"  href="https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/TREMFYA-pi.pdf">Prescribing Information</a> and <a target="_blank"  href="https://www.janssenlabels.com/package-insert/product-patient-information/TREMFYA-medication-guide.pdf">Medication Guide</a> for guselkumab at TremfyaHCP.com. Provide the Medication Guide to your patients and encourage discussion.</b><br/><br/>
        The material on this site is intended only as informational or as an educational aid and it is not intended to be taken as medical advice. The ultimate responsibility for patient care resides with a healthcare professional. Third party trademarks used herein are trademarks of their respective owners. &#xA9; Janssen Biotech, inc. 06/22 cp-314692v1</span></div>`,
      },
    ],
  },
};

function App() {
  //create function to loop through img_wrapper class

  //fire after load
  // useEffect(() => {
  //   const images = document.querySelectorAll(".img_wrapper");
  //   images.forEach((image) => {
  //     image.addEventListener("scroll", (e) => {
  //       image.classList.add("img_wrapper_clicked");
  //     });
  //   });
  // }, []);
  // useEffect(() => {
  //   //console log width
  //   console.log(window.innerWidth);
  //   if (window.innerWidth < 720) {
  //     const images = document.querySelectorAll(".WideImageBlock");
  //     images.forEach((image) => {
  //       image.addEventListener("scroll", (e) => {
  //         image.classList.add("img_wrapper_clicked");
  //       });
  //     });
  //   }
  // }, []);

  //#safety-isi click

  //window on scroll
  //interval

  //page on scroll

  //fire isi on scroll
  //useeffect to add class to image wrapper
  useGTM("GTM-PD7QX3T", "globalDataLayer");
  return (
    <div className="App">
      {data.content.body.map((block) => Components(block))}
      <ISIComponent />
    </div>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);